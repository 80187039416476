import { useGetAPI, usePostAPI, usePatchAPI, useDeleteAPI } from '@api';
import { useRecoilValue } from 'recoil';
import { authenticatedUser, SID, XCustomerID } from '@state';
import {
	AJAXError,
	QuotesResponse,
	TRateCard,
	TBookShipmentShipAPI,
	APICallProps,
	TShipmentEOS,
	IShipperQuoteSession,
	TShipmentOrder,
	TConfirmReorder,
	TInvoiceBilling,
	TInvoiceDetails,
	TShipperSettings,
} from '@types';

/*
 *	SHIPPER
 */
export const useGetShipperOrders = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/orders' });

	return {
		loadingOrders: isLoading,
		orders: data as { rows: TShipmentEOS[] },
		getOrdersError: error as AJAXError,
		clearGetOrdersError: clearError,
		callGetOrders: callAPI as (props?: APICallProps) => Promise<{
            data: { rows: TShipmentEOS[] } | null;
            error: AJAXError | null
        }>
	}
}
export const useGetShipperOrders2 = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({
		endpoint: '/shipper/orders',
		domain: 'Inertia'
	});

	return {
		loadingOrders: isLoading,
		orders: data as { rows: TShipmentEOS[] },
		getOrdersError: error as AJAXError,
		clearGetOrdersError: clearError,
		callGetOrders2: callAPI as (props?: APICallProps) => Promise<{
            data: { rows: TShipmentEOS[] } | null;
            error: AJAXError | null
        }>
	}
}

export const useConfirmQuickReorder = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({ endpoint: '/api/shipper/reorder/quick/confirm', headers });

	return {
		loadingConfirmQuickReorder: isLoading,
		confirmQuickReorder: data as TConfirmReorder,
		confirmQuickReorderError: error as AJAXError,
		clearConfirmQuickReorderError: clearError,
		clearConfirmQuickReorderData: clearData,
		callConfirmQuickReorder: callAPI as (props?: APICallProps) => Promise<{
            data: TConfirmReorder | null;
            error: AJAXError | null
        }>
	}
}
export const useConfirmQuickReorder2 = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({
		endpoint: '/shipper/reorder/quick/confirm',
		domain: 'Inertia'
	});

	return {
		loadingConfirmQuickReorder2: isLoading,
		confirmQuickReorder: data as TConfirmReorder,
		confirmQuickReorderError: error as AJAXError,
		clearConfirmQuickReorderError: clearError,
		clearConfirmQuickReorderData: clearData,
		callConfirmQuickReorder2: callAPI as (props?: APICallProps) => Promise<{
            data: TConfirmReorder | null;
            error: AJAXError | null
        }>
	}
}

export const useCreateQuickReorder = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({ endpoint: '/api/shipper/reorder/quick', headers });

	return {
		loadingCreateQuickReorder: isLoading,
		createQuickReorder: data as any,
		createQuickReorderError: error as AJAXError,
		clearCreateQuickReorderError: clearError,
		clearCreateQuickReorderData: clearData,
		callCreateQuickReorder: callAPI as (props?: APICallProps) => Promise<{
            data: any | null;
            error: AJAXError | null
        }>
	}
}

export const useGetShipperOrder = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/order/{1}' });

	return {
		loadingOrder: isLoading,
		order: data as TShipmentOrder,
		getOrderError: error as AJAXError,
		clearGetOrderError: clearError,
		clearGetOrderData: clearData,
		callGetOrder: callAPI as (props?: APICallProps) => Promise<{
            data: TShipmentOrder | null;
            error: AJAXError | null
        }>
	}
}

export const useGetQuoteSession = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/quote-session', headers: headers });

	return {
		loadingQuoteSession: isLoading,
		quoteSession: data as { data: { shipper_quote_session: IShipperQuoteSession }},
		getQuoteSessionError: error as AJAXError,
		clearQuoteSessionError: clearError,
		callGetQuoteSession: callAPI as (props?: APICallProps) => Promise<{
            data: { shipper_quote_session: IShipperQuoteSession } | null;
            error: AJAXError | null
        }>
	}
}

export const useGetPartialShipment = () => {
	const shipperID = useRecoilValue(XCustomerID);
	const headers = shipperID ? { 'X-Customer-Id': `${shipperID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({
		endpoint: '/api/shipment/{1}',
		domain: 'ShipmentAPI',
		headers
	});

	return {
		loadingPartialShipment: isLoading,
		partialShipment: data?.data as TBookShipmentShipAPI,
		getPartialShipmentError: error as AJAXError,
		clearPartialShipmentError: clearError,
		callGetPartialShipment: callAPI as (props?: APICallProps) => Promise<{
            data: { data: TBookShipmentShipAPI } | null;
            error: AJAXError | null
        }>
	}
}

export const usePostBookShipment = () => {
	const shipperID = useRecoilValue(XCustomerID);
	const headers = shipperID ? { 'X-Customer-Id': `${shipperID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = usePostAPI({
		endpoint: '/api/partial-shipment',
		domain: 'ShipmentAPI',
		headers
	});

	return {
		loadingBookShipment: isLoading,
		bookedShipment: data?.data as TBookShipmentShipAPI,
		bookShipmentError: error as AJAXError,
		clearBookShipmentError: clearError,
		callBookShipment: callAPI as (props?: APICallProps) => Promise<{
            data: { data: TBookShipmentShipAPI } | null;
            error: AJAXError | null
        }>
	}
}

export const useSaveShipmentProgress = () => {
	const shipperID = useRecoilValue(XCustomerID);
	const headers = shipperID ? { 'X-Customer-Id': `${shipperID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePatchAPI({
		endpoint: '/api/partial-shipment/{1}',
		domain: 'ShipmentAPI',
		headers
	});

	return {
		loadingSaveShipment: isLoading,
		savedShipment: data?.data as TBookShipmentShipAPI,
		savedShipmentError: error as AJAXError,
		clearSavedShipmentError: clearError,
		clearSavedShipmentData: clearData,
		callSaveShipment: callAPI as (props?: APICallProps) => Promise<{
            data: TBookShipmentShipAPI | null;
            error: AJAXError | null
        }>
	}
}

export const useFinalizeShipment = () => {
	const shipperID = useRecoilValue(XCustomerID);
	const headers = shipperID ? { 'X-Customer-Id': `${shipperID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({
		endpoint: '/api/partial-shipment/{1}/finalize',
		domain: 'ShipmentAPI',
		headers
	});

	return {
		loadingFinalizeShipment: isLoading,
		finalizedShipment: data?.data as TBookShipmentShipAPI,
		finalizedShipmentError: error as AJAXError,
		clearFinalizeShipmentError: clearError,
		clearFinalizeShipmentData: clearData,
		callFinalizeShipment: callAPI as (props?: APICallProps) => Promise<{
            data: { data: TBookShipmentShipAPI } | null;
            error: AJAXError | null
        }>
	}
}

export const useCreateQuoteFromBooking = () => {
	const sID = useRecoilValue(SID);
	const authUser = useRecoilValue(authenticatedUser);
	const shipperToken = sID && sID > 0 ? sID : authUser?.shipper?.id;
	const headers = shipperToken ? { 'X-Shipper-Token': `${shipperToken}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({
		endpoint: '/api/shipper/booking/quote',
		headers
	});

	return {
		loadingCreateQuote: isLoading,
		createdQuote: data as any,
		createdQuoteError: error as AJAXError,
		clearCreatedQuoteError: clearError,
		clearCreatedQuoteData: clearData,
		callCreateQuoteFromBooking: callAPI as (props?: APICallProps) => Promise<{
            data: any | null;
            error: AJAXError | null
        }>
	}
}

export const useRemoveShipmentDoc = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = useDeleteAPI({
		endpoint: '/api/stop-document/{1}',
		domain: 'ShipmentAPI'
	});

	return {
		loadingRemoveDoc: isLoading,
		removedDoc: data?.data as { success: boolean },
		removedDocError: error as AJAXError,
		clearRemovedDocError: clearError,
		clearRemovedDocData: clearData,
		callRemoveDoc: callAPI as (props?: APICallProps) => Promise<{
            data: { success: boolean } | null;
            error: AJAXError | null
        }>
	}
}

export const usePostBookingPrice = () => {
	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({
		endpoint: '/api/partial-shipment/price',
		domain: 'ShipmentAPI'
	});

	return {
		loadingBookingPrice: isLoading,
		bookingPrice: data as TRateCard,
		bookingPriceError: error as AJAXError,
		clearBookingPriceError: clearError,
		clearBookingPriceData: clearData,
		callPostBookingPrice: callAPI as (props?: APICallProps) => Promise<{
            data: TRateCard | null;
            error: AJAXError | null
        }>
	}
}

export const usePostBookingPrices = () => {
	const shipperID = useRecoilValue(XCustomerID);
	const headers = shipperID ? { 'X-Customer-Id': `${shipperID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		clearData,
		callAPI
	} = usePostAPI({
		endpoint: '/api/partial-shipment/prices',
		domain: 'ShipmentAPI',
		headers
	});

	return {
		loadingBookingPrices: isLoading,
		bookingPrices: data?.data as TRateCard[],
		bookingPricesError: error as AJAXError,
		clearBookingPricesError: clearError,
		clearBookingPricesData: clearData,
		callPostBookingPrices: callAPI as (props?: APICallProps) => Promise<{
            data: {
				data: TRateCard[];
			} | null
            error: AJAXError | null
        }>
	}
}

export const useGetShipmentQuote = () => {
	const sID = useRecoilValue(SID);
	const authUser = useRecoilValue(authenticatedUser);
	const shipperToken = sID && sID > 0 ? sID : authUser?.shipper?.id;
	const headers = shipperToken ? { 'X-Shipper-Token': `${shipperToken}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/booking/quote/{1}', headers });

	return {
		loadingShipmentQuote: isLoading,
		shipmentQuote: data as { quote: TBookShipmentShipAPI },
		shipmentQuoteError: error as AJAXError,
		clearShipmentQuoteError: clearError,
		callGetShipmentQuote: callAPI as (props?: APICallProps) => Promise<{
            data: { quote: TBookShipmentShipAPI };
            error: AJAXError | null
        }>
	}
}

export const useGetShipmentIdFromRef = () => {
	const sID = useRecoilValue(SID);
	const headers = sID ? { 'X-Shipper-Token': `${sID}` } : undefined;

	const {
		isLoading,
		data,
		error,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/idfromref/{1}', headers });

	return {
		loadingIdFromRef: isLoading,
		idFromRef: data as { id: number, external_id: string, reference: string },
		idFromRefError: error as AJAXError,
		clearIdFromRefError: clearError,
		callGetIdFromRef: callAPI as (props?: APICallProps) => Promise<{
            data: { id: number, external_id: string, reference: string } | null;
            error: AJAXError | null
        }>
	}
}

/*
 *	ADMIN -> SHIPPER -> SHIPMENTS
 */
export const useGetShipperQuotes = () => {
	const sID = useRecoilValue(SID);
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/shipper/quotes?{1}',
		headers: { 'X-Shipper-Token': `${sID}` },
		domain: 'EOS'
	});

	const triggerAPI = (query:string|null = null) => {
		const apiParams = query ? { routes: [query] } : {};
		callAPI(apiParams);
	}

	return {
		loadingQuotes: isLoading,
		quotes: data as QuotesResponse,
		getQuotesError: error,
		callGetQuotes: triggerAPI
	}
}

/*
 * SHIPPER -> INVOICES
 */
export const useGetShipperInvoices = () => {
	const sID = useRecoilValue(SID);
	const authUser = useRecoilValue(authenticatedUser);
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/shipper/invoices',
		headers: { 'X-Shipper-Token': `${sID ?? authUser?.shipper?.id}` },
	});

	return {
		loadingInvoices: isLoading,
		invoices: data as any,
		getInvoicesError: error,
		callGetShipperInvoices: callAPI as (props?: APICallProps) => Promise<{
			data: TInvoiceBilling;
			error: AJAXError | null
		}>
	}
}

export const useGetShipperInvoice = () => {
	const sID = useRecoilValue(SID);
	const authUser = useRecoilValue(authenticatedUser);
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/shipper/invoices/{1}/detail',
		headers: { 'X-Shipper-Token': `${sID ?? authUser?.shipper?.id}` },
	});

	return {
		loadingInvoice: isLoading,
		invoice: data as TInvoiceDetails,
		getInvoicesError: error,
		callGetShipperInvoice: callAPI as (props?: APICallProps) => Promise<{
			data: TInvoiceDetails;
			error: AJAXError | null
		}>
	}
}

export const useDownloadShipperInvoice = () => {
	const sID = useRecoilValue(SID);
	const authUser = useRecoilValue(authenticatedUser);
	const {
		isLoading,
		data,
		error,
		callAPI
	} = useGetAPI({
		endpoint: '/api/shipper/invoices/{1}/download',
		headers: { 'X-Shipper-Token': `${sID ?? authUser?.shipper?.id}` },
	});

	return {
		loadingDownloadInvoice: isLoading,
		shipperInvoice: data as any,
		shipperInvoiceError: error,
		callDownloadShipperInvoice: callAPI as (props?: APICallProps) => Promise<{
			data: any;
			error: AJAXError | null
		}>
	}
}

export const useGetShipperSettings = () => {
	const {
		isLoading,
		data,
		error,
		clearData,
		clearError,
		callAPI
	} = useGetAPI({ endpoint: '/api/shipper/shipper-settings' });

	return {
		loadingShipperSettings: isLoading,
		shipperSettings: data as TShipperSettings,
		getShipperSettingsError: error,
		clearGetShipperSettings: clearData,
		clearGetShipperSettingsError: clearError,
		callGetShipperSettings: callAPI as (props?: APICallProps) => Promise<{
			data: TShipperSettings;
			error: AJAXError | null;
		}>
	}
}
