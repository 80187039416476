import { Suspense, lazy, useEffect, useState } from 'react';
import {
	Routes,
	Route,
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import SuspenseLoader from '@pages/SuspenseLoader';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { jwtDecode } from 'jwt-decode';
import {
	UserID,
	CompanyID,
	IsAdmin,
	JWT,
	UserRoles,
	WaitForGuest,
	JWTUserType,
	DecodedJWT,
	ShipperSettings,
	authenticatedUser
} from '@state';
import { TJWT } from '@types';
import Redirect from '@pages/Redirect';
import { clearPendoIdentify, clearCookies } from '@utils';
import { useImpersonation } from '@hooks';
import { useIsGuest, useGetShipperSettings } from '@api';

// --> Generic Pages
import MainLayout from '@pages/MainLayout';
import RoleGuard from '@pages/RoleGuard';
import FreightSafeLogin from '@pages/FreightSafe/FSLogin/FSLogin';
import FSDetails from '@pages/FreightSafe/FSDetails/FSDetails';
const NotFound = lazy(() => import('@pages/NotFound'));

// --> Public Pages
const Home = lazy(() => import('@pages/Public/Home'));
const Login = lazy(() => import('@pages/Public/Login'));
const ResetPassword = lazy(() => import('@pages/Public/ResetPassword'));

// --> Shipper Pages
const ShipperRouter = lazy(() => import('@pages/Shipper'));
const ShipperDashboard = lazy(() => import('@pages/Shipper/Dashboard/Dashboard'));
const ShipperBookingIndex = lazy(() => import('@pages/Shipper/Booking'));
const BookingDashboard = lazy(() => import('@pages/Shipper/Booking/BookingDashboard'));
const BookingCreateIndex = lazy(() => import('@pages/Shipper/Booking/BookShipment/CreateIndex'));
const BookingCreateStep1 = lazy(() => import('@pages/Shipper/Booking/BookShipment/Step1Wrapper'));
const BookingCreateStep2 = lazy(() => import('@pages/Shipper/Booking/BookShipment/Step2'));
const BookingLabels = lazy(() => import('@pages/Shipper/Booking/Labels'));
const QuoteComplete = lazy(() => import('@pages/Shipper/Booking/QuoteComplete'));
const ShipperQuotes = lazy(() => import('@pages/Shipper/Quotes/Quotes'));
const ShipperCompanyRouter = lazy(() => import('@pages/Shipper/Company/'));
const ShipperCompany = lazy(() => import('@pages/Shipper/Company/Company'));
const ShipperCompanyLocationsDetails = lazy(() => import('@pages/Shipper/Company/Locations/LocationsDetails/LocationsDetails'));
const ShipperInvoices = lazy(() => import('@pages/Shipper/Invoices/'));
const ShipperInvoiceDetails = lazy(() => import('@pages/Shipper/Invoices/Detail'));

// --> Carrier Pages
const CarrierRouter = lazy(() => import('@pages/Carrier'));
const CarrierDashboard = lazy(() => import('@pages/Carrier/Dashboard/Dashboard'));

/*
 *	Below are all ADMIN Pages, grouped by Top Navigation
 */
// --> Inbox Pages
const InboxRouter = lazy(() => import('@pages/Inbox'));
const InboxQuotes = lazy(() => import('@pages/Inbox/Quotes'));
const InboxNewShippers = lazy(() => import('@pages/Inbox/NewShippers'));
const InboxNewCarriers = lazy(() => import('@pages/Inbox/NewCarriers'));


// --> Fulfilment
const FulfilmentRouter = lazy(() => import('@pages/Fulfilment'));
const FulfilmentUnallocated = lazy(() => import('@pages/Fulfilment/Unallocated'));
const SuperDockRouter = lazy(() => import('@pages/Fulfilment/SuperDock'));
const SuperDock = lazy(() => import('@pages/Fulfilment/SuperDock/SuperDock'));
const SuperDockDetails = lazy(() => import('@pages/Fulfilment/SuperDock/Details'));
const SuperDockConfigure = lazy(() => import('@pages/Fulfilment/SuperDock/Configure'));
const SuperDockAllocate = lazy(() => import('@pages/Fulfilment/SuperDock/Allocate'));
const CarrierRecommendation = lazy(() => import('@pages/Fulfilment/CarrierRecommendation'));

// --> Transport
const TransportRouter = lazy(() => import('@pages/Transport'));

// --> Finance
const FinanceRouter = lazy(() => import('@pages/Finance'));
const FinanceShipperBilling = lazy(() => import('@pages/Finance/ShipperBilling'));
const FinanceShipperBillingDetail = lazy(() => import('@pages/Finance/ShipperBilling/Detail'));
const FinanceInvoiceReport = lazy(() => import('@pages/Finance/InvoiceReport'));
const FinanceCarrierBilling = lazy(() => import('@pages/Finance/CarrierBilling'));
const FinanceCarrierBillingDetail = lazy(() => import('@pages/Finance/CarrierBilling/Detail'));
const FinanceIssueAndClaim = lazy(() => import('@pages/Finance/IssueAndClaim/Pending'));
const FinanceHistoricalIssueAndClaim = lazy(() => import('@pages/Finance/IssueAndClaim/Historical'))
const FinanceInvoiceManagement = lazy(() => import('@pages/Finance/InvoiceManagement'));
const InvoiceManagementDetailPage = lazy(() => import('@pages/Finance/InvoiceManagement/Detail'));

// --> Configuration
const ConfigurationRouter = lazy(() => import('@pages/Configuration'));
const ConfigurationCompany = lazy(() => import('@pages/Configuration/Company'));
const ConfigurationLocations = lazy(() => import('@pages/Configuration/Locations/LocationPage'));
const ConfigurationTruckTypes = lazy(() => import('@pages/Configuration/TruckTypes'));
const ConfigurationDashboard = lazy(() => import('@pages/Configuration/Dashboard'));
const ConfigurationFuelLevy = lazy(() => import('@pages/Configuration/FuelLevy'));
const ConfigurationMarketplace = lazy(() => import('@pages/Configuration/Marketplace'));
const ConfigurationLocation = lazy(() => import('@pages/Configuration/Locations/ConfigureLocation'));
const ConfigurationLocationContacts = lazy(() => import('@pages/Configuration/Locations/LocationContacts'));
const ConfigurationLocationConnectedShippers = lazy(() => import('@pages/Configuration/Locations/ConnectedShippers'));
const ConfigurationRegisteredCarriers = lazy(() => import('@pages/Configuration/RegisteredCarriers'));
const ConfigurationRegisteredShippers = lazy(() => import('@pages/Configuration/RegisteredShipper'));

const Router = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const setUserRoles = useSetRecoilState(UserRoles);
	const setUserID = useSetRecoilState(UserID);
	const setUserType = useSetRecoilState(JWTUserType);
	const setCompanyID = useSetRecoilState(CompanyID);
	const setIsAdmin = useSetRecoilState(IsAdmin);
	const setDecodedJWT = useSetRecoilState(DecodedJWT);
	const [Jwt, setJWT] = useRecoilState(JWT);
	const [waitForGuest, setWaitForGuest] = useRecoilState(WaitForGuest);
	const [initComplete, setInitComplete] = useState(false);
	const authUser = useRecoilValue(authenticatedUser);
	const setShipperSettings = useSetRecoilState(ShipperSettings);
	const { impersonate } = useImpersonation();

	const {
		guestData,
		callGetGuestAPI
	} = useIsGuest();
	const { callGetShipperSettings } = useGetShipperSettings();

	const logoutCleanup = async () => {
		setSearchParams({});
		if ((window as any).pendo) {
			clearPendoIdentify();
		}
		await sessionStorage.clear();
		await localStorage.removeItem('jwt');
		await localStorage.removeItem('refreshToken');
		await clearCookies();
		setJWT(null);
		if (['/', '/register', '/reset'].indexOf(location.pathname) < 0) {
			let searching;
			if (location.pathname === '/login') {
				searching = location.search;
			} else {
				searching = `?backto=${location.pathname}${location.search}`;
			}
			navigate(`/login${searching}`);
		}
	}

	const fetchSettings = (userType: string) => {
		if (userType && userType === 'shipper') {
			callGetShipperSettings({ params: { shipper_id: `${authUser?.shipper?.id}` }, headers: { 'X-Shipper-Token': `${authUser?.shipper?.id}` } }).then((res) => {
				if (res) {
					setShipperSettings(res.data);
				}
			}).catch((err) => {
				if (err && err.cause) console.error(err);
			})
		}
	}

	const init = async () => {
		const forceLogout = searchParams.get('exit');
		if (forceLogout !== null) {
			logoutCleanup();
			return;
		}
		const storedJWT = await localStorage.getItem('jwt');
		if (storedJWT) {
			setJWT(storedJWT);
			const decoded: TJWT = jwtDecode(storedJWT);
			setUserID(decoded.user_id);
			setUserType(decoded.user_type);
			setCompanyID(decoded.company_id);
			setIsAdmin(decoded.is_admin);
			setUserRoles(decoded.roles);
			setDecodedJWT(decoded);
			// If user has jwt token and try to access '/', '/login', we should redirect them to inbox page
			if (['/', '/login', '/freight-safe/login'].indexOf(location.pathname) > -1) {
				if (decoded.roles?.includes('mf_freight_user')) {
					window.location.href = '/admin/shipper/overview';
				} else if (decoded.is_admin) {
					navigate(`/inbox/quotes`);
				} else {
					window.location.href = `${window.location.origin}/${decoded.user_type}`;
				}
			} else {
				fetchSettings(decoded.user_type);
			}
		} else if (['/', '/register', '/freight-safe/login'].indexOf(location.pathname) < 0) {
			let searching;
			if (location.pathname === '/login') {
				searching = location.search;
			} else {
				searching = `?backto=${location.pathname}${location.search}`;
			}
			navigate(`/login${searching}`);
		}
		await impersonate();
		setInitComplete(true);
	}

	useEffect(() => {
		if (!waitForGuest) {
			init();
		}
	}, [Jwt, waitForGuest]);

	useEffect(() => {
		if (guestData) {
			const loc = ['/freight-safe/login', '/freight-safe/details'].indexOf(location.pathname) >= 0;
			if (loc) {
				setWaitForGuest(false);
				return;
			}
			if (guestData.isGuest) {
				logoutCleanup();
			} else {
				setWaitForGuest(false);
			}
		}
	}, [guestData]);

	useEffect(() => {
		callGetGuestAPI();
	}, [Jwt]);

	return (
		<Suspense fallback={<SuspenseLoader />}>
			<Routes>
				{/* PUBLIC ROUTES */}
				<Route index element={<Home />} />
				<Route path={'login'} element={<Login />} />
				<Route path={'reset'} element={<ResetPassword />} />
				<Route path={'freight-safe'}>
					<Route path={'login'} element={<FreightSafeLogin />} />
					<Route index path={'details'} element={<FSDetails />} />
				</Route>

				{/* SHIPPER/CARRIER ROUTES */}
				<Route path={'/'} element={<MainLayout initComplete={initComplete} />}>
					<Route path={'shipper'} element={<ShipperRouter />}>
						<Route path={'invoices'} element={<ShipperInvoices />} />
						<Route path={'invoices/detail/:id'} element={<ShipperInvoiceDetails />} />
						<Route index path={'dashboard'} element={<ShipperDashboard />} />
						<Route index path={':id/dashboard'} element={<ShipperDashboard />} />
						<Route path={'booking'} element={<ShipperBookingIndex />}>
							<Route path={'dashboard'} element={<BookingDashboard />} />
							<Route path={'create'}>
								<Route index element={<BookingCreateIndex />} />
								<Route index path={'step1'} element={<BookingCreateStep1 />} />
								<Route path={'step2'} element={<Redirect path={'/shipper/booking/dashboard'} />} />
								<Route path={'step2/:id'} element={<BookingCreateStep2 />} />
								<Route path={'label'} element={<Redirect path={'/shipper/booking/dashboard'} />} />
								<Route path={'label/:reference'} element={<BookingLabels />} />
							</Route>
							<Route path={'quotecomplete'} element={<QuoteComplete />} />
						</Route>
						<Route index path={':id/quotes'} element={<ShipperQuotes />} />
						<Route path={'company'} element={<ShipperCompanyRouter />}>
							<Route path={'locations'} element={<ShipperCompany />} />
							<Route path={'locations/:id'} element={<ShipperCompanyLocationsDetails /> } />
						</Route>
					</Route>
					<Route path={'carrier'} element={<CarrierRouter />}>
						<Route index path={'dashboard'} element={<CarrierDashboard />} />
					</Route>
				</Route>

				{/* ADMIN ROUTES */}
				<Route path={'/'} element={<MainLayout requireAdmin initComplete={initComplete} />}>
					<Route path={'/'} element={<RoleGuard roles={['mf_freight_user']} redirect={'/admin/shipper'} domain={'eos'} />}>
						<Route path={'inbox'} element={<InboxRouter />}>
							<Route index element={<InboxQuotes />} />
							<Route index path={'quotes'} element={<InboxQuotes />} />
							<Route path={'new_shippers'} element={<InboxNewShippers />} />
							<Route path={'new_carriers'} element={<InboxNewCarriers />} />
						</Route>
					</Route>
					<Route path={'fulfilment'} element={<FulfilmentRouter />}>
						<Route index element={<FulfilmentUnallocated />} />
						<Route index path={'unallocated'} element={<FulfilmentUnallocated />} />
						<Route path={'superdock'} element={<SuperDockRouter />}>
							<Route index element={<SuperDock />} />
							<Route path={'configure'} element={<Redirect path={'/fulfilment/superdock'} />} />
							<Route path={'configure/:id'} element={<SuperDockConfigure />} />
							<Route path={'allocate'} element={<Redirect path={'/fulfilment/superdock'} />} />
							<Route path={'allocate/:id'} element={<SuperDockAllocate />} />
							<Route path={':id'} element={<SuperDockDetails />} />
						</Route>
						<Route path={'carrier_recommendation'} element={<CarrierRecommendation />} />
					</Route>
					<Route path={'transport'} element={<TransportRouter />}>

					</Route>
					<Route path={'finance'} element={<FinanceRouter />}>
						<Route path={'carrier_billing'}>
							<Route index element={<FinanceCarrierBilling />} />
							<Route path={'detail'} element={<FinanceCarrierBillingDetail />} />
							<Route path={'detail/:id'} element={<FinanceCarrierBillingDetail />} />
						</Route>
						<Route path={'shipper_billing'}>
							<Route index element={<FinanceShipperBilling />} />
							<Route path={'detail'} element={<FinanceShipperBillingDetail />} />
							<Route path={'detail/:id'} element={<FinanceShipperBillingDetail />} />
						</Route>
						<Route path={'invoice_report'} element={<FinanceInvoiceReport />} />
						<Route path={'issues'} element={<FinanceIssueAndClaim />} />
						<Route path={'historical-issues'} element={<FinanceHistoricalIssueAndClaim />} />
						<Route path={'invoice_management/detail/:id'} element={<InvoiceManagementDetailPage />} />
						<Route path={'invoice_management/*'} element={<FinanceInvoiceManagement />} />
					</Route>
					<Route path={'configuration'} element={<ConfigurationRouter />}>
						<Route index path={'dashboard'} element={<ConfigurationDashboard />} />
						<Route path={'company'} element={<ConfigurationCompany />} />
						<Route path={'truck_types'} element={<ConfigurationTruckTypes />} />
						<Route path={'locations'} element={<ConfigurationLocations />} />
						<Route path={'fuel-levy'} element={<ConfigurationFuelLevy />} />
						<Route path={'marketplace'} element={<ConfigurationMarketplace />} />
						<Route path={'locations/create'} element={<ConfigurationLocation/>} />
						<Route path='locations/:id' element={<ConfigurationLocation />} />
						<Route path={'locations/:id/:route'} element={<ConfigurationLocation/>} />
						<Route path={'locations/:id/contacts'} element={<ConfigurationLocationContacts/>} />
						<Route path={'locations/:id/shippers'} element={<ConfigurationLocationConnectedShippers/>} />
						<Route path={'registered-carriers'} element={<ConfigurationRegisteredCarriers />} />
						<Route path={'registered-shippers'} element={<ConfigurationRegisteredShippers />} />
					</Route>
				</Route>

				{/* NOT FOUND... */}
				<Route path={'*'} element={<NotFound />} />
			</Routes>
		</Suspense>
	);
}

export default Router;
