import { atom, selector } from 'recoil';
import {
	TAdminTeamRow,
	TNotificationConfigs,
	TAdminCompanyDetails,
	TJWT
} from '@types';
import { ActiveShipper } from './App';

/* eslint-disable */
const keys = {
	JWT:					'@Ofload/JWT',
	DECODED_JWT:			'@Ofload/DecodedJWT',
	USERID:					'@Ofload/UserID',
	USERTYPE:				'@Ofload/User/UserType',
	COMPANYID:				'@Ofload/CompanyID',
	ISADMIN:				'@Ofload/IsAdmin',
	USERNAME:				'@Ofload/UserName',
	COMPANYNAME:			'@Ofload/CompanyName',
  	USER_ROLES: 			'@Ofload/UserRoles',
	WAIT_FOR_GUEST:			'@Ofload/WaitForGuest',
	AUTHENTICATED_USER:		'@Ofload/User/CurrentUser',
	MOST_PRIV_ROLE:			'@Ofload/User/MostPrivilegedRole',
	TEAM_MEMBER_DETAILS:	'@Ofload/TeamMemberDetails',
	TEAM_MEMBER_COMS:		'@Ofload/TeamMemberComs',
	COMPANY_DETAILS:		'@Ofload/CompanyDetails',
	COMPANY_ROLES:			'@Ofload/CompanyRoles',
	X_CUSTOMER_ID:			'@Ofload/XCustomerID'
}
/* eslint-enable */

export const authenticatedUser = atom<AuthenticatedUser | null>({
	key: keys.AUTHENTICATED_USER,
	default: null
});

export const JWT = atom<string | null>({
	key: keys.JWT,
	default: null
});

export const DecodedJWT = atom<TJWT | null>({
	key: keys.DECODED_JWT,
	default: null
});

export const UserID = atom<number | string | null>({
	key: keys.USERID,
	default: null
});

export const JWTUserType = atom<number | string | null>({
	key: keys.USERTYPE,
	default: null
});

export const CompanyID = atom<number | string | null>({
	key: keys.COMPANYID,
	default: null
});

export const IsAdmin = atom<boolean>({
	key: keys.ISADMIN,
	default: false
});

export const XCustomerID = selector<string | null>({
	key: keys.X_CUSTOMER_ID,
	get: ({ get }) => {
		const userType = get(JWTUserType);
		const customerID = get(DecodedJWT)?.customer_id;
		const isAdmin = get(IsAdmin);
		const activeCustomerID = get(ActiveShipper)?.customer_id;
		if (!isAdmin && userType !== 'shipper') return null;
		if (activeCustomerID) return `${activeCustomerID}`;
		if (!isAdmin && customerID) return `${customerID}`;
		return null;
	}
});

export const UserName = selector<string>({
	key: keys.USERNAME,
	get: ({ get }) => {
		const user = get(authenticatedUser);
		return user ? `${user.team.first_name} ${user.team.last_name}` : '';
	}
});

export const CompanyName = selector<string>({
	key: keys.COMPANYNAME,
	get: ({ get }) => {
		const user = get(authenticatedUser);
		return user ? user.company.company_name : '';
	}
});

export const UserRoles = atom<string[]>({
	key: keys.USER_ROLES,
	default: []
});

export const WaitForGuest = atom<boolean>({
	key: keys.WAIT_FOR_GUEST,
	default: true
});

export const MostPrivRole = selector<Role | null>({
	key: keys.MOST_PRIV_ROLE,
	get: ({ get }) => {
		const user = get(authenticatedUser);
		const roles = user?.roles;
		if (roles) {
			const sorted = [...roles].sort((a, b) => {
				return a.access_level > b.access_level ?
					1 : a.access_level < b.access_level ?
						-1 : 0;
			});
			return sorted[0];
		}
		return null;
	}
});

export const NewMember = {
	id: 0,
	first_name: '',
	firstname: '',
	last_name: '',
	lastname: '',
	position: '',
	title: '',
	display_name: '',
	email: '',
	phone: '',
	timezone: '',
	trade_name: '',
	can_access_portal: true,
	user_role: '',
	user_id: 0,
	access_level: 0,
	is_primary: false,
	external_id: '',
	can_see_all_cost_centers: false,
	is_mobile_user: false,
	notification_configurations: {},
	company_id: '',
	abn: '',
	company_name: '',
	team_id: ''
}

export const TeamMemberDetails = atom<TAdminTeamRow | null>({
	key: keys.TEAM_MEMBER_DETAILS,
	default: null
});

export const CompanyRoles = atom<string[]>({
	key: keys.COMPANY_ROLES,
	default: []
});

export const TeamMemberComs = atom<TNotificationConfigs | null>({
	key: keys.TEAM_MEMBER_COMS,
	default: null
});

export const CompanyDetails = atom<TAdminCompanyDetails | null>({
	key: keys.COMPANY_DETAILS,
	default: null
});
