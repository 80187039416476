import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { navigator, TShipperSettings } from '@types';

const shipperNav = (
	enabled:boolean,
	sID:string = '',
	isAdmin: boolean = false,
	userRoles: string[] = [],
	shipperSettings: TShipperSettings | null = null
) => {

	const sIDQuery = sID ? `?sid=${sID}` : '';

	let shipperMenuItems = [
		{
			label: 'Dashboard',
			icon: (color:string) => <DashboardOutlinedIcon style={{ color: color }} />,
			link: `/admin/shipper/overview${sIDQuery}`,
			domain: 'eos',
			enabled: true,
			activeURL: 'dashboard'
		},
		{
			label: 'Today',
			icon: (color:string) => <TodayOutlinedIcon style={{ color: color }} />,
			link: `/shipper${sIDQuery}`,
			domain: 'eos',
			enabled,
			activeURL: 'shipper'
		},
		{
			label: 'Shipments',
			icon: (color:string) => <ListAltIcon style={{ color: color }} />,
			link: `/shipper/orders${sIDQuery}`,
			domain: 'eos',
			enabled,
			activeURL: 'orders'
		},
		{
			label: 'Upload Shipments',
			icon: (color:string) => <UploadFileIcon style={{ color: color }} />,
			link: `/shipper/shipment-data-import${sIDQuery}`,
			enabled,
			domain: 'eos',
			activeURL: 'shipment-data-import'
		},
		{
			label: 'Book a Shipment',
			icon: (color:string) => <ControlPointIcon style={{ color: color }} />,
			link: `/shipper/booking/dashboard${sIDQuery}`,
			enabled,
			activeURL: `booking`
		},
		{
			label: 'Rate Cards',
			icon: (color:string) => <PriceChangeOutlinedIcon style={{ color: color }} />,
			link: `/shipper/ratecards${sIDQuery}`,
			domain: 'eos',
			enabled,
			activeURL: 'ratecards'
		},
		{
			label: 'Quotes',
			icon: (color:string) => <RequestQuoteOutlinedIcon style={{ color: color }} />,
			link: `/shipper/quotes${sIDQuery}`,
			domain: 'eos',
			enabled,
			activeURL: 'quotes'
		},
		{
			label: 'Invoices',
			icon: (color:string) => <ReceiptOutlinedIcon style={{ color: color }} />,
			link: `/shipper/invoices${sIDQuery}`,
			domain: 'spa',
			enabled,
			activeURL: 'invoices'
		},
		{
			label: 'Analytics',
			icon: (color:string) => <TrendingUpOutlinedIcon style={{ color: color }} />,
			link: `/shipper/analytics${sIDQuery}`,
			domain: 'eos',
			enabled,
			activeURL: 'analytics'
		},
		{
			label: 'Company Details',
			icon: (color:string) => <StoreOutlinedIcon style={{ color: color }} />,
			link: `/shipper/company${sIDQuery}`,
			domain: 'eos',
			enabled: true,
			activeURL: 'company'
		},
		{
			label: 'Settings',
			icon: (color:string) => <SettingsOutlinedIcon style={{ color: color }} />,
			link: `/shipper/settings${sIDQuery}`,
			domain: 'eos',
			enabled: true,
			activeURL: 'settings'
		}
	] as navigator[];

	shipperMenuItems = shipperMenuItems.filter((item: navigator) => {
		switch (item.activeURL) {
		case 'dashboard':
		case 'ratecards':
		case 'settings':
			return isAdmin;
		case 'shipment-data-import':
			return isAdmin || (shipperSettings?.bulk_upload_shipments_enabled ?? false);
		case 'quotes':
		case 'invoices':
		case 'analytics':
		case 'company':
			// TODO: check how this behaves when logged in as a shipper
			return userRoles.some((r) => ['admin', 'team', 'finance', 'manager', 'operation'].includes(r));
		default:
			return true;
		}
	});

	return shipperMenuItems;
}

export default shipperNav;
